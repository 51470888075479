import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './client/components/home/home';
import Electrical from './client/components/electrical/electrical';
import Mechanical from './client/components/me/me';
import Civil from './client/components/ce/ce';
import Ece from './client/components/ece/ece';
import Cs from './client/components/cs/cs';
import It from './client/components/it/it';
import SideBar from './client/components/relatedLinksSideBar/links';
import Faculty from './client/components/faculty/faculty';
import Form from './client/components/addFacultyForm/form';
import Profile from './client/components/profile/profile';
import LoginForm from './client/components/login/login';
import SignUpForm from './client/components/signup/signup';

const App = () => {
  
  return (
    <Router>
      <Routes>
        <Route path="/sign-up" element={<SignUpForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/" element={<Home />} />
        <Route path="/electrical" element={<Electrical />} />
        <Route path="/mechanical" element={<Mechanical />} />
        <Route path="/civil" element={<Civil />} />
        <Route path="/ece" element={<Ece />} />
        <Route path="/cs" element={<Cs />} />
        <Route path="/it" element={<It />} />
        <Route path="/link" element={<SideBar />} />
        <Route path="/faculty" element={<Faculty />} />
        <Route path="/add-faculty-form" element={<Form />} />
        <Route path="/add-faculty-form/:userId" element={<Form />} />
        <Route path="/profile/:userId" element={<Profile />} />
      </Routes>
    </Router>
  );
};

export default App;
