import React, { useState } from 'react';
import './login.css';
import { useNavigate } from 'react-router-dom';

function LoginForm() {
    const [username, setUsename] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const validateForm = () => {
        const newErrors = {};
        if (!username) {
            newErrors.username = 'Username is required';
        } else if (!/\S+@\S+\.\S+/.test(username)) {
            newErrors.username = 'Username address is invalid';
        }
        if (!password) {
            newErrors.password = 'Password is required';
        } else if (password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters long';
        }
        return newErrors;
    };

    // const Login = () => {
    //     const formErrors = validateForm();
    //     if (Object.keys(formErrors).length === 0) {
    //         console.log('Form is valid! Submitting...', { username, password });
    //         const data = { username, password};
    //         fetch("http://localhost:3001/signin", {
    //             method: "POST",
    //             headers: {
    //                 Accept: "application/json",
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify(data),
    //         })
    //             .then((res) => res.json())
    //             .then((res) => {
    //                 if (res.message == "Success") {
    //                     localStorage.setItem('auth-token',res.token)
    //                     console.log('User Logged in  Successfully');
    //                     navigate('/home');
    //                 }
    //                 else{
    //                     setMessage(res.message);
    //                 }
    //             });
    //     } else {
    //         console.error('Form is invalid!', formErrors);
    //         setErrors(formErrors);
    //     }
    // };
    const Login = async () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            console.log('Form is valid! Submitting...', { username, password });
            const data = { username, password };
            
            try {
                const response = await fetch("https://faculty-backend-o8gg.onrender.com/signin", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
                
                const res = await response.json();
                
                if (res.message === "Success") {
                    // Here, localStorage.setItem() is used after the fetch operation has completed.
                    console.log('User Logged in Successfully');
                    localStorage.setItem('auth-token', res.token);
                    
                    // Assuming `navigate` is available in this context. Make sure it is defined or imported.
                    navigate('/');
                } else {
                    setMessage(res.message); // Assuming setMessage is a function that handles error messages.
                }
            } catch (error) {
                console.error('An error occurred during login:', error);
            }
        } else {
            console.error('Form is invalid!', formErrors);
            setErrors(formErrors); // Assuming setErrors is a function that handles form errors.
        }
    };
    

    return (
        <>
        <div className="overlay"></div>
        <div className='login-container'>
            <h2 style={{textAlign:'center'}}>Login</h2>
            <div style={{textAlign:'center', color:'red'}}>{message}</div>
            <div className='login-content-username'>
                <input
                    type="email"
                    name="username"
                    placeholder='username'
                    value={username}
                    onChange={(e) => setUsename(e.target.value)}
                />
                {errors.username && <p style={{fontSize:'14px',color:'red'}}>{errors.username}</p>}
            </div>
            <div className='login-content'>
                <input
                    type="password"
                    name="password"
                    placeholder='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {errors.password && <p style={{fontSize:'14px',color:'red'}}>{errors.password}</p>}
            </div>
            <div className='login-content'>
                <button onClick={Login}>Login</button>
            </div>
        </div>
        </>
    );
}

export default LoginForm;
