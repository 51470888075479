import React from 'react';
import './navbar.css';
import { Link, useNavigate } from 'react-router-dom';
import { getUserRole } from '../../helper';

const Navbar = () => {
    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
        navigate('/login');
    }
    const login = () => {
        navigate('/login');
    }
    const newUser = () => {
        navigate('/sign-up')
    }
    return (
        <nav className='nav-container'>
            <ul>
                <li><Link to="/">Home</Link></li>
                <li className='expandable'>
                    <a href='#'>
                        Academics
                    </a>
                    <div>
                        <div className="sub-menu">
                            <h4>Departments</h4>
                            <div className='menu-flex'>
                                <div className='dept'>
                                    <Link className="sub-menu-link" to="/cs">Computer Science and Engineering</Link>
                                    <Link className="sub-menu-link" to="/it">Information Technology</Link>
                                    <Link className="sub-menu-link" to="/electrical">Electrical Engineering</Link>
                                </div>
                                <div className='dept last'>
                                    <Link className="sub-menu-link" to="/ece">Electronics and Communication</Link>
                                    <Link className="sub-menu-link" to="/civil">Civil Engineering</Link>
                                    <Link className="sub-menu-link" to="/mechanical">Mechanical Engineering</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {getUserRole() === 'admin' ?
                    <button style={{ height: '30px', marginRight: '10px', cursor: 'pointer' }} onClick={newUser}>Create New User</button> :
                    ""
                }
                {getUserRole() === 'anon' ?
                    <button style={{ height: '30px', marginRight: '10px', cursor: 'pointer' }} onClick={login}>Login</button> :
                    <button style={{ height: '30px', marginRight: '10px', cursor: 'pointer' }} onClick={logout}>Logout</button>}
            </div>
        </nav>
    );
};

export default Navbar;