import React from "react";
import SimpleImageSlider from "react-simple-image-slider";
import Navbar from "../navbar/navbar";
import "./home.css";
import AuthGuard from "../../authGaurd";
const Home = () => {
    const images = [
        { url: require("../../images/ce.jpeg") },
        { url: require("../../images/cs.jpeg") },
        { url: require("../../images/me.jpeg") },
        { url: require("../../images/ee.jpeg") },
        { url: require("../../images/ec.jpeg") },
    ];

    const App = () => {
        return (
            <div>
                <div>
                    <SimpleImageSlider
                        width={1464}
                        height={604}
                        images={images}
                        showBullets={true}
                        showNavs={true}
                        // style={{ marginLeft: "17px", marginTop: "5px" }}
                        slideDuration={0.5}
                        autoPlay={true}
                    />
                </div>
            </div>

        );
    }
    return (
        <div className="container">
            <Navbar />
            <App />
            <div className="home-about">
                <div className="about-us-content">
                    <h2>About Us</h2>
                    <p>
                        With a rich heritage of academic excellence and a forward-thinking approach, our college provides a vibrant learning environment where students are empowered to explore their passions, unleash their creativity, and push the boundaries of knowledge. Our diverse and experienced faculty members are not only experts in their fields but also mentors who inspire and guide students to reach their full potential.
                    </p>
                </div>
                <div className="dir-img">
                    <p style={{ fontSize: "18px", color: "#ffffff",backgroundColor:"brown",fontFamily: "'Montserrat', sans-serif",fontWeight: "bold" }}>Director</p>
                    <img src={require("../../images/director.jpeg")} />
                    <p style={{ fontSize: "18px", color: "brown" }}>Shri Jaipal Meel</p>

                </div>
            </div>

        </div>
    );

}
export default AuthGuard(Home, ['user', 'anon']);