import React, { useEffect, useRef, useState } from 'react';
import './drawer.css'; // Make sure to create this CSS file
import { useParams } from 'react-router-dom';
import { hasAccess } from '../../helper';

function VerticalDrawer() {
    const [isOpen, setIsOpen] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [publicationTitle, setPublicationTitle] = useState('')
    const [year, setYear] = useState('')
    const [inputYear, setInputYear] = useState('');
    const [publicationLink, setPublicationLink] = useState('')
    const [publishPdf, setPublishPdf] = useState('')
    const [userData, setUserData] = useState([]);
    const [showFilterData, setShowFilterData] = useState(false);
    const bottomRef = useRef(null);
    const { userId } = useParams();
    const [filteredData, setFilteredData] = useState(userData);
    console.log('this', userId);
    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    }
    const addPublication = () => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        setShowForm(!showForm);
    }
    const loadData = () =>{
        fetch(`https://faculty-backend-o8gg.onrender.com/getPublication/${userId}`)
        .then((res) => res.json())
        .then((res) => {
            if (res.message == "Success") {
                setUserData(res.data);
            }
        })
        .catch(error => console.error('Error Fetching users:', error));
    }
    useEffect(() => {
        loadData();
    }, []);
    // useEffect(() => {
    //     if (userData) {
    //      setUserData(userData);
    //     }
    //   }, [userData]); 
    // const convertToBase64 = (e) => {
    //     console.log('userData', userData);
    //     var reader = new FileReader();
    //     reader.readAsDataURL(e.target.files[0]);
    //     reader.onload = () => {
    //         console.log(reader.result);
    //         setPublishPdf(reader.result);

    //     };
    //     reader.onerror = error => {
    //         console.log("Error", error);
    //     }
    // };
    const handlePDFFileChange = (e) => {
        setPublishPdf(e.target.files[0]);
    }
    const AddData = () => {
        const data = { publicationTitle, publicationLink, publishPdf, year, userId };
        var d = new FormData();
        d.append('publicationTitle', publicationTitle);
        d.append('publicationLink', publicationLink);
        d.append('publishPdf', publishPdf);
        d.append('year', year);
        d.append('userId', userId);
        fetch("https://faculty-backend-o8gg.onrender.com/savePublication", {
            method: "POST",
            // headers: {
            //     Accept: "application/json",
            //     "Content-Type": "application/json",
            // },
            body: d,
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.message == "Success") {
                    setShowForm(false);
                    loadData();
                }
            });
    };
    const change = (event) => {
        const { value } = event.target;
        setInputYear(value);
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (inputYear.trim() === '') {
                setFilteredData(userData);
            }
            else {
                if (userData) {
                    setShowFilterData(true);
                    const result = userData.filter(item => item.year === inputYear);
                    console.log('result', result);
                    setFilteredData(result);
                }
            }
        }
    };
    const deletePub = (id) => {
        console.log('identity', id);
        if (window.confirm('Publication will be Deleted')) {
            fetch(`https://faculty-backend-o8gg.onrender.com/deletePublication/${id}`, {
                method: 'DELETE',
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.message === "Success") {
                        console.log("Publication Deleted Successfully");
                        loadData();
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    return (
        <div>
            <div style={{ backgroundColor: 'brown', height: '2.5em', display: 'flex', justifyContent: 'space-between', padding: '5px' }}>
                <div style={{ display: 'flex', gap: '16px' }}>
                    <h2 style={{ marginTop: '0px', color: '#fff' }}>Publication</h2>
                    {hasAccess(userId) && <button onClick={addPublication} style={{ border: 'none', backgroundColor: '#fff', fontWeight: 'bold', cursor: 'pointer' }}>Add New +</button>}
                </div>
                <button onClick={toggleDrawer} style={{ height: '2.5em', width: '2.5em', backgroundColor: 'white', fontSize: '15px' }}>
                    {isOpen ? '-' : '+'}
                </button>
            </div>
            <div className={`drawer ${isOpen ? 'open' : ''}`}>
                <div style={{ textAlign: 'right' }}>
                    <input type="text" style={{ height: '30px', width: '200px' }} onChange={change} onKeyDown={handleKeyDown} />
                </div>
                {/* Drawer content goes here */}
                {showFilterData && filteredData.map((x) => (
                    <>
                        <div>
                            <p style={{ backgroundColor: '#FFF8E7', color: '#795548', border: '1px solid black', padding: '5px', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>{`${x.publicationTitle} - ${x.year}`}
                                <span>
                                    <i class="material-icons" onClick={() => deletePub(x._id)} style={{ cursor: 'pointer' }}>delete</i>
                                </span>
                            </p>
                        </div>
                        <div style={{ backgroundColor: '#fff' }}>
                            <a href={x.publicationLink}>Download Papers</a>
                        </div>
                        <div style={{}}>
                            <p style={{ backgroundColor: ' #FFF0B3', color: '#795548', border: '1px solid black', padding: '5px', fontWeight: 'bold' }}>Research PDF</p>
                            <a href={'https://faculty-backend-o8gg.onrender.com/downloadFile/'+x.publishPdf} download={x.title+'.pdf'}>Download</a>
                        </div>
                        <hr />
                    </>
                ))
                }
                {!showFilterData && userData.map((x) => (
                    <>
                        <div>
                            <p style={{ backgroundColor: '#FFF8E7', color: '#795548', border: '1px solid black', padding: '5px', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>{`${x.publicationTitle} - ${x.year}`}
                                <span>
                                    <i class="material-icons" onClick={() => deletePub(x._id)} style={{ cursor: 'pointer' }}>delete</i>
                                </span>
                            </p>
                        </div>
                        <div style={{ backgroundColor: '#fff' }}>
                            <a href={x.publicationLink}>Download Papers</a>
                        </div>
                        <div style={{}}>
                            <p style={{ backgroundColor: ' #FFF0B3', color: '#795548', border: '1px solid brown', padding: '5px', fontWeight: 'bold' }}>Research PDF</p>
                            {/* <a href={x.publishPdf} download>Download</a> */}
                            <a href={'https://faculty-backend-o8gg.onrender.com/downloadFile/'+x.publishPdf} download={x.title+'.pdf'}>Download</a>
                        </div>
                        <hr />
                    </>
                ))
                }
            </div>
            <div className={`form ${showForm ? 'show' : ''}`}>
                <input type='text' placeholder='Title Of Publication' style={{ display: 'block', height: '30px', width: '300px', marginTop: '10px' }} onChange={(e) => setPublicationTitle(e.target.value)} />
                <input type='url' placeholder='Publication Link' style={{ display: 'block', height: '30px', width: '300px', marginTop: '10px' }} value={publicationLink} onChange={(e) => setPublicationLink(e.target.value)} />
                <input type='file' placeholder='PDF' style={{ display: 'block', height: '30px', width: '300px', marginTop: '10px' }} onChange={handlePDFFileChange} />
                <input type='text' placeholder='Year' style={{ display: 'block', height: '30px', width: '300px', marginTop: '10px' }} onChange={(e) => setYear(e.target.value)} />
                <button onClick={(AddData)}>Add</button>
            </div>
            <div ref={bottomRef} />
        </div>
    );
}

export default VerticalDrawer;
