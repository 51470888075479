import React, { useEffect, useState } from "react";
import Navbar from "../navbar/navbar";
import './profile.css';
import Header from "../header/header";
import { useParams } from "react-router-dom";
import VerticalDrawer from "../../common/drawer/drawer";
import AuthGuard from "../../authGaurd";


const Profile = () => {
    const {userId} = useParams();
    const [user, setUser]= useState([]);
    useEffect(()=>{
        fetch(`https://faculty-backend-o8gg.onrender.com/getuser/${userId}`)
        .then((res) => res.json())
        .then((res) => {
            if (res.message == "Success")
            {
                setUser(res.data);
            }
        })
        .catch(error => console.error('Error Fetching users:', error));
    },[]);
    if (!user) {
        return <div>Loading...</div>;
    }
    return (
        <div className="container">
            <Header />
            <Navbar />
            <div className="container-content">
                <div className="about-content">
                    <div className="userImage">
                    {user.image && <img src={'https://faculty-backend-o8gg.onrender.com/downloadFile/'+user.image.split('\\').reverse()[0]}/>}
                    {!user.image && <img src={require("../../images/png-transparent-default-avatar-thumbnail.png")} alt="image" />}

                    </div>
                </div>
                <div className="profile-content">
                    <div className="profile-content-header">
                        <h2>BIOSKETCH</h2>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: user.content }}></div>
                    <div>
                        <VerticalDrawer />
                    </div>
                </div>
                {/* <div className="sideBar-content">
                    <SideBar />
                </div> */}
            </div>
        </div>

    );
}

export default AuthGuard(Profile, ['user', 'anon']);