import React from "react";
import Navbar from "../navbar/navbar";
import './cs.css';
import Header from "../header/header";
import SimpleImageSlider from "react-simple-image-slider";
import { useNavigate } from "react-router-dom";
import AuthGuard from "../../authGaurd";

const Cs = () => {
    const images = [
        { url: require("../../images/img1.jpg") },
        { url: require("../../images/ee.jpeg") },
        { url: require("../../images/img3.jpg") },
    ];
    const navigate = useNavigate();
    const App = () => {
        return (
            <div>
                <div>
                    <SimpleImageSlider
                        width={1000}
                        height={400}
                        images={images}
                        showBullets={true}
                        showNavs={true}
                        slideDuration={0.5}
                        autoPlay={true}
                    />
                </div>
            </div>

        );
    }
    const peoples = () => {
        navigate('/faculty');
    }
    return (
        <div className="container-electrical">
            <Header />
            <Navbar />
            <div className="container-ee">
                <div className="department-content">
                    <h1>Welcome To The Department Of Computer Science Engineering</h1>
                    <div className="container-btn">
                        <button onClick={peoples}>Faculty</button>
                    </div>
                    <div className="app-slider">
                        <App />
                    </div>
                </div>
                <div className="about-dept">
                    <p>The Department of Computer Science and Engineering at SKIT is a hub of technological innovation and educational rigor, offering a curriculum that spans the latest in computing theory and practical application. Catered to fostering the next generation of tech leaders and innovators, the department provides both undergraduate and graduate programs that delve deeply into areas such as artificial intelligence, software development, cybersecurity, and data analytics. Faculty members, who are both scholars and industry veterans, bring a wealth of knowledge and real-world insights, ensuring students receive a well-rounded education that emphasizes both theoretical foundations and practical skills.</p>
                    <p>The department's state-of-the-art computer labs, collaborative project spaces, and active coding clubs create a vibrant learning environment where students can experiment with new technologies and work on projects that have real-world implications. By emphasizing collaboration, innovation, and ethical computing, the Department of Computer Science and Engineering at SKIT prepares students to excel in a rapidly evolving digital world.</p>
                </div>
            </div>
        </div>

    );
}

export default AuthGuard(Cs, ['user', 'anon']);