import React from "react";
import Navbar from "../navbar/navbar";
import './it.css';
import Header from "../header/header";
import SimpleImageSlider from "react-simple-image-slider";
import { useNavigate } from "react-router-dom";
import AuthGuard from "../../authGaurd";

const It = () => {
    const images = [
        { url: require("../../images/img1.jpg") },
        { url: require("../../images/ee.jpeg") },
        { url: require("../../images/img3.jpg") },
    ];
    const navigate = useNavigate();
    const App = () => {
        return (
            <div>
                <div>
                    <SimpleImageSlider
                        width={1000}
                        height={400}
                        images={images}
                        showBullets={true}
                        showNavs={true}
                        slideDuration={0.5}
                        autoPlay={true}
                    />
                </div>
            </div>

        );
    }
    const peoples = () => {
        navigate('/faculty');
    }
    return (
        <div className="container-electrical">
            <Header />
            <Navbar />
            <div className="container-ee">
                <div className="department-content">
                    <h1>Welcome To The Department Of Information Technology</h1>
                    <div className="container-btn">
                        <button onClick={peoples}>Faculty</button>
                    </div>
                    <div className="app-slider">
                        <App />
                    </div>
                </div>
                <div className="about-dept">
                    <p>The Department of Information Technology at SKIT is recognized for its cutting-edge curriculum and strong emphasis on digital technologies and their applications in the modern world. This department offers programs that prepare students for the complexities of IT infrastructure, network security, database management, and web development. With a faculty composed of experienced professionals and dedicated researchers, students gain exposure to the latest advancements and best practices in the field. The department prides itself on its practical approach to education, encouraging students to participate in internships, collaborative projects, and workshops that enhance their technical skills and professional readiness.</p>
                    <p>State-of-the-art computer labs and dedicated project areas provide an ideal environment for students to engage in hands-on learning and innovation. The Department of Information Technology at XYZ College aims to produce skilled professionals who are not only technologically adept but also equipped to meet the challenges of an increasingly digital and interconnected world.</p>
                </div>
            </div>
        </div>

    );
}

export default AuthGuard(It, ['user', 'anon']);