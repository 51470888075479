import React, { useEffect, useState } from "react";
import { Editor } from '@tinymce/tinymce-react';
import Navbar from "../navbar/navbar";
import './form.css';
import Header from "../header/header";
import { useNavigate, useParams } from "react-router-dom";
import AuthGuard from "../../authGaurd";

const Form = () => {
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [designation, setDesignation] = useState('');
    const [image, setImage] = useState('');
    const [userData, setUserData] = useState([]);
    const [content, setContent] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const { userId } = useParams();
    useEffect(() => {
        fetch(`https://faculty-backend-o8gg.onrender.com/getUser/${userId}`)
            .then((res) => res.json())
            .then((res) => {
                if (res.message == "Success") {
                    setEmail(res.data.email);
                    setUserName(res.data.userName);
                    setDesignation(res.data.designation);
                    setImage(res.data.image);
                    setContent(res.data.content);
                    console.log(res.data);
                }
            })
            .catch(error => console.error('Error Fetching users:', error));
    }, [userId])
    const isCancel = () => {
        navigate('/faculty')
    }
    const handleEditorChange = (content) => {
        setContent(content);
        console.log(content);
    }
    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        // var reader = new FileReader();
        // const file = e.target.files[0];
        // reader.readAsDataURL(e.target.files[0]);
        // reader.onload = () => {
        //     console.log(reader.result);
        //     if (file.type.startsWith('image/')) {
        //         setImage(reader.result);
        //     }
        //     // else if (file.type === 'application/pdf') {
        //     //     setPdf(reader.result);
        //     // }
        // };
        // reader.onerror = error => {
        //     console.log("Error", error);
        // }
    };
    const submitData = (userId, data) => {
        const method = userId ? "PUT" : "POST";
        const url = `https://faculty-backend-o8gg.onrender.com/saveUser/${userId}`;
        //const url = `http://localhost:3001/saveUser/${userId}`;
        fetch(url, {
            method: method,
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.message === "Success") {
                    setUserData((currentUserData) => [...currentUserData, res.data]);
                    reset();
                    navigate('/faculty');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    const AddData = () => {
        const data = { email , userName, designation, image, content };
        var d = new FormData();
        d.append('email', email);
        d.append('userName', userName);
        d.append('designation', designation);
        d.append('image', image);
        d.append('content', content);
        const id = userId ? userId : '';
            submitData(id, d);
    };


    const reset = () => {
        setEmail("");
        setUserName("");
        setDesignation("");
        setImage("");
    };
    return (
        <div className="container">
            <Header />
            <Navbar />
            <div className="form-container">
                <div className="form-input">
                    <label>Email</label>
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="form-input">
                    <label>Name</label>
                    <input type="text" placeholder="Name" value={userName} onChange={(e) => setUserName(e.target.value)} />
                </div>
                <div className="form-input">
                    <label>Designation</label>
                    <input type="text" placeholder="Designation" value={designation} onChange={(e) => setDesignation(e.target.value)} />
                </div>
                <div className="form-input">
                    <label>Image</label>
                    <input type="file" onChange={handleImageChange} />
                </div>
                <Editor
                    apiKey='o8kes8up4zsl7rtkdysouolxf7extmgsgptscjz18lca00qj'
                    initialValue=""
                    init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat'
                    }}
                    onEditorChange={handleEditorChange}
                />
                <div className="form-btn">
                    <button onClick={isCancel}>Cancel</button>
                    <button onClick={AddData}>Add</button>
                </div>
            </div>
        </div>
    );
}

export default AuthGuard(Form, ['user']);