export function jwtDecode(t) {
    let token = {};
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return (token)
}

export const getUserRole = () => {
    const t = localStorage.getItem('auth-token');
    if (t) {
        const tk = jwtDecode(t);
        return tk.payload.role;
    }
    return 'anon';
}

export const getUsername = () => {
    const t = localStorage.getItem('auth-token');
    if (t) {
        const tk = jwtDecode(t);
        return tk.payload.username;
    }
    return 'anon';
}

export const getUserId = () => {
    const t = localStorage.getItem('auth-token');
    if (t) {
        const tk = jwtDecode(t);
        return tk.payload.id;
    }
    return 'anon';
}

export const getUserIdOfUserModel = () => {
    const t = localStorage.getItem('auth-token');
    if (t) {
        const tk = jwtDecode(t);
        return tk.payload.user_id;
    }
    return 'anon';
}
export const hasAccess = (userName) => getUserRole() === 'admin' || (getUserRole() === 'user' && (getUsername() === userName || getUserId() === userName || getUserIdOfUserModel()===userName));

