import React from "react";
import "./header.css";

const Header = () => {
    return (
        <div className="container-header">
            <img src={require("../../images/skit_logo.png")} />
            <div className="container-header-text">
                Swami Keshvanand Institute of Technology,
                Management & Gramothan, Jaipur
            </div>
           
        </div>
    );
}

export default Header;