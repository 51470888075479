import React from "react";
import Navbar from "../navbar/navbar";
import './ece.css';
import Header from "../header/header";
import SimpleImageSlider from "react-simple-image-slider";
import { useNavigate } from "react-router-dom";
import AuthGuard from "../../authGaurd";

const Ece = () => {
    const images = [
        { url: require("../../images/img1.jpg") },
        { url: require("../../images/ee.jpeg") },
        { url: require("../../images/img3.jpg") },
    ];
    const navigate = useNavigate();
    const App = () => {
        return (
            <div>
                <div>
                    <SimpleImageSlider
                        width={1000}
                        height={400}
                        images={images}
                        showBullets={true}
                        showNavs={true}
                        slideDuration={0.5}
                        autoPlay={true}
                    />
                </div>
            </div>

        );
    }
    const peoples = () => {
        navigate('/faculty');
    }
    return (
        <div className="container-electrical">
            <Header />
            <Navbar />
            <div className="container-ee">
                <div className="department-content">
                    <h1>Welcome To The Department Of Electronics & Communication Engineering</h1>
                    <div className="container-btn">
                        <button onClick={peoples}>Faculty</button>
                    </div>
                    <div className="app-slider">
                        <App />
                    </div>
                </div>
                <div className="about-dept">
                    <p>The Department of Electronics and Communications at SKIT is a premier destination for students aspiring to delve into the realms of electronic circuits, communication systems, and signal processing. The department offers robust programs at both the undergraduate and graduate levels, meticulously designed to blend theoretical knowledge with practical technological skills. Students are exposed to a spectrum of topics including digital electronics, wireless communication, embedded systems, and microprocessors, all crucial for understanding modern communication technologies. Faculty members, noted for their scholarly expertise and industry experience, provide invaluable mentorship, guiding students through complex projects and research opportunities.</p>
                    <p>Additionally, the department boasts state-of-the-art laboratories equipped with the latest tools and technologies, enabling students to engage in innovative experimentation and real-world problem solving. This hands-on approach not only enhances learning but also prepares students for successful careers in the fast-evolving electronics and communications industries. Through rigorous training and a dynamic curriculum, the Department of Electronics and Communications at SKIT empowers students to become leaders in their field, ready to tackle global challenges with advanced technological solutions.</p>
                </div>
            </div>
        </div>

    );
}

export default AuthGuard(Ece, ['user', 'anon']);