import React, { useEffect, useState } from 'react';
import './signup.css';
import { useNavigate } from 'react-router-dom';
import AuthGuard from '../../authGaurd';

function SignUpForm() {
    const [username, setUsename] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [role, setRole] = useState('');
    const navigate = useNavigate();
    const validateForm = () => {
        const newErrors = {};
        if (!username) {
            newErrors.username = 'Username is required';
        } else if (!/\S+@\S+\.\S+/.test(username)) {
            newErrors.username = 'Username address is invalid';
        }
        if (!password) {
            newErrors.password = 'Password is required';
        } else if (password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters long';
        }
        if(!role){
            newErrors.role = 'Please Select a role'
        }
        return newErrors;
    };
    useEffect(()=>{
        setUsename('');
        setPassword('');
    },[]);

    const Signup = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            console.log('Form is valid! Submitting...', { username, password });
            const data = { username, password, role};
            fetch("https://faculty-backend-o8gg.onrender.com/signup", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.message == "Success") {
                        setMessage('User Created Successfully');
                            navigate('/login')
                    }
                });
        } else {
            console.error('Form is invalid!', formErrors);
            setErrors(formErrors);
        }
        
    };

    return (
        <>
        <div className="overlay"></div>
        <div className='login-container'>
        <h2 style={{textAlign:'center'}}>SignUp</h2>
        <div style={{textAlign:'center', color:'red'}}>{message}</div>
            {message}
            <div className='login-content-username'>
                <input
                    type="email"
                    name="username"
                    placeholder='username'
                    value={username}
                    onChange={(e) => setUsename(e.target.value)}
                />
                {errors.username && <p style={{fontSize:'14px',color:'red'}}>{errors.username}</p>}
            </div>
            <div className='login-content'>
                <input
                    type="password"
                    name="password"
                    placeholder='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {errors.password && <p style={{fontSize:'14px',color:'red'}}>{errors.password}</p>}
            </div>
            <div className='login-content'>
                <select onChange={(e) => setRole(e.target.value)}>
                    <option defaultValue="Select a Role">Select a Role</option>
                    <option>admin</option>
                    <option>user</option>
                </select>
                {errors.role && <p style={{fontSize:'14px',color:'red'}}>{errors.role}</p>}
            </div>
            <div className='login-content'>
                <button onClick={Signup}>Signup</button>
            </div>
        </div>
        </>
    );
}

export default AuthGuard(SignUpForm , ['admin']);
