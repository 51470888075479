import React from "react";
import './links.css';
import { Link } from "react-router-dom";
const SideBar = () =>{
    return(
        <div className="related-links">
            <h2>
                Related Links
            </h2>
            <ul>
                <li><Link className="sideBar-link" to="/electrical">Home</Link></li>
                <li><Link className="sideBar-link" to="/faculty">People</Link></li>
                <li><a>Academics & Time Table</a></li>
                <li><a>Facilities</a></li>
                <li><a>Publications</a></li>
                <li><a>Projects</a></li>
                <li><a>Patents</a></li>
                <li><a>Event Organized</a></li>
                <li><a>Awards & Honours</a></li>
                <li><a>Electrical Engg. Society</a></li>
                <li><a>Placements</a></li>
                <li><a>Photo Gallery</a></li>
                <li><a>Contact Us</a></li>
            </ul>
        </div>
    );
}
export default SideBar;