// AuthGuard.js

import React from 'react';
import { Link, Navigate, Redirect } from 'react-router-dom';
import { getUserRole } from './helper';

const AuthGuard = (WrappedComponent, userRoles) => {
    const isAuthenticated = localStorage.getItem('auth-token'); // Assuming token is stored in localStorage
    const roles = userRoles;
    return (props) => {
        return (roles.indexOf('anon') > -1 || isAuthenticated) ? (
            (roles.indexOf('anon') > -1 || roles.concat('admin').indexOf(getUserRole()) !== -1) ? <WrappedComponent {...props} /> : <Navigate to="/" />
        ) : (
            <Navigate to="/login" />
        );
    };
};

export default AuthGuard;
