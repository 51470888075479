import React, { useEffect, useState } from "react";
import Navbar from "../navbar/navbar";
import './faculty.css';
import Header from "../header/header";
import SideBar from "../relatedLinksSideBar/links";
import { useNavigate } from "react-router-dom";
import AuthGuard from "../../authGaurd";
import { getUserRole, getUsername, hasAccess } from "../../helper";

const Faculty = () => {
    const navigate = useNavigate();
    const AddFaculty = () => {
        navigate('/add-faculty-form');
    }
    const MoveBack = () => {
        navigate('/electrical');
    }
    const handleEdit = (userId) => {
        navigate(`/add-faculty-form/${userId}`);
    };
    const handleImageClick = (userId) => {
        navigate(`/profile/${userId}`); // Assuming profile page route is '/profile/:userId'
    };
    const [users, setUsers] = useState([]);
    const refreshItems = () => {
        //fetch('http://localhost:3001/getUser')
       fetch('https://faculty-backend-o8gg.onrender.com/getUser')
            .then((res) => res.json())
            .then((res) => {
                if (res.message == "Success") setUsers(res.data);
            })
            .catch(error => console.error('Error Fetching users:', error));
    }
    useEffect(() => {
        refreshItems();
    }, []);
    const deleteUser = (id) => {
        if (window.confirm('User will be Deleted')) {
            fetch(`https://faculty-backend-o8gg.onrender.com/deleteUser/${id}`, {
                method: 'DELETE',
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.message === "Success") {
                        console.log("User Deleted Successfully")
                        refreshItems();
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };
    return (
        <div className="container">
            <Header />
            <Navbar />
            <div className="container-faculty-content">
                <div className="about-faculty-content">
                    <div style={{ display: 'flex', gap: '10px', padding: 0 }}>
                        {getUserRole() === 'admin' && <button onClick={AddFaculty}>Add Faculty</button>}
                        <button onClick={MoveBack}>Go Back</button>
                    </div>
                    <div className="flex-container">
                        {users.map((user) => {
                            let img = user.image;
                            console.log(img)
                            if(img){
                                img = img.split('\\').reverse()[0];
                            }
                            return <div className="flex-container-content" key={user._id}>
                                {/* {img && <img src={'https://faculty-backend-o8gg.onrender.com/getfile/'+img} alt="image" onClick={() => handleImageClick(user._id)} />} */}
                                {!img && <img src={require("../../images/png-transparent-default-avatar-thumbnail.png")} alt="image" onClick={() => handleImageClick(user._id)}/> }
                                {/* {img && <img src={'http://localhost:3001/downloadFile/'+img} alt="image" onClick={() => handleImageClick(user._id)}/>} */}
                                {img && <img src={'https://faculty-backend-o8gg.onrender.com/downloadFile/'+img} alt="image" onClick={() => handleImageClick(user._id)}/>}
                                <h4>{user.email}</h4>
                                <h4>{user.userName}</h4>
                                <p>{user.designation}</p>
                                {hasAccess(user.email) && <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                    <button onClick={() => handleEdit(user._id)}>Edit</button>
                                    <button onClick={() => deleteUser(user._id)}>Delete</button>
                                </div>}
                            </div>
})}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AuthGuard(Faculty, ['user', 'anon']);