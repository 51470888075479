import React from "react";
import Navbar from "../navbar/navbar";
import './me.css';
import Header from "../header/header";
import SimpleImageSlider from "react-simple-image-slider";
import { useNavigate } from "react-router-dom";
import AuthGuard from "../../authGaurd";

const Mechanical = () => {
    const images = [
        { url: require("../../images/img1.jpg") },
        { url: require("../../images/ee.jpeg") },
        { url: require("../../images/img3.jpg") },
    ];
    const navigate = useNavigate();
    const App = () => {
        return (
            <div>
                <div>
                    <SimpleImageSlider
                        width={1000}
                        height={400}
                        images={images}
                        showBullets={true}
                        showNavs={true}
                        slideDuration={0.5}
                        autoPlay={true}
                    />
                </div>
            </div>

        );
    }
    const peoples = () => {
        navigate('/faculty');
    }
    return (
        <div className="container-electrical">
            <Header />
            <Navbar />
            <div className="container-ee">
                <div className="department-content">
                    <h1>Welcome To The Department Of Mechanical Engineering</h1>
                    <div className="container-btn">
                        <button onClick={peoples}>Faculty</button>
                    </div>
                    <div className="app-slider">
                        <App />
                    </div>
                </div>
                <div className="about-dept">
                    <p>The Department of Mechanical Engineering at SKIT stands as a beacon of academic excellence and innovative research within the engineering community. Esteemed for its comprehensive curriculum and hands-on approach to education, the department offers both undergraduate and graduate programs that equip students with fundamental and advanced knowledge in mechanics, thermodynamics, fluid dynamics, and material science. Staffed by a dedicated faculty renowned for their expertise and commitment to student success, the department fosters a collaborative environment where students are encouraged to engage in cutting-edge research projects, ranging from sustainable energy solutions to advanced robotics.</p>
                    <p>The department’s state-of-the-art facilities, including modern laboratories and design studios, provide students with the practical skills and real-world experiences necessary to excel in the dynamic field of mechanical engineering, preparing them to become leaders who will contribute to technological innovation and industrial advancements.</p>
                </div>
            </div>
        </div>

    );
}

export default AuthGuard(Mechanical, ['user', 'anon']);