import React from "react";
import Navbar from "../navbar/navbar";
import './ce.css';
import Header from "../header/header";
import SimpleImageSlider from "react-simple-image-slider";
import { useNavigate } from "react-router-dom";
import AuthGuard from "../../authGaurd";

const Civil = () => {
    const images = [
        { url: require("../../images/img1.jpg") },
        { url: require("../../images/ee.jpeg") },
        { url: require("../../images/img3.jpg") },
    ];
    const navigate = useNavigate();
    const App = () => {
        return (
            <div>
                <div>
                    <SimpleImageSlider
                        width={1000}
                        height={400}
                        images={images}
                        showBullets={true}
                        showNavs={true}
                        slideDuration={0.5}
                        autoPlay={true}
                    />
                </div>
            </div>

        );
    }
    const peoples = () => {
        navigate('/faculty');
    }
    return (
        <div className="container-electrical">
            <Header />
            <Navbar />
            <div className="container-ee">
                <div className="department-content">
                    <h1>Welcome To The Department Of Civil Engineering</h1>
                    <div className="container-btn">
                        <button onClick={peoples}>Faculty</button>
                    </div>
                    <div className="app-slider">
                        <App />
                    </div>
                </div>
                <div className="about-dept">
                    <p>The Department of Civil Engineering at SKIT is distinguished for its rigorous academic program and its commitment to addressing global infrastructure challenges. This department offers a comprehensive range of courses that cover essential aspects of civil engineering, including structural analysis, geotechnical engineering, environmental engineering, and urban planning. Students are trained to think critically and apply their knowledge to real-world problems, such as designing sustainable buildings, improving transportation networks, and managing water resources efficiently. The faculty, comprised of seasoned professionals and dedicated researchers, actively engage students in learning through innovative teaching methods and hands-on project work.</p>
                    <p>The department also emphasizes the importance of sustainable practices and safety in engineering design, preparing students to become responsible and effective professionals. State-of-the-art facilities, such as the structural testing lab and the hydraulics laboratory, provide students with the practical experience necessary to succeed in the diverse field of civil engineering. By fostering a collaborative and supportive learning environment, the Department of Civil Engineering at SKIT aims to produce graduates who are well-prepared to create resilient and sustainable infrastructure that meets the needs of society.</p>
                </div>
            </div>
        </div>

    );
}

export default AuthGuard(Civil, ['user', 'anon']);