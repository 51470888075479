import React from "react";
import Navbar from "../navbar/navbar";
import './electrical.css';
import Header from "../header/header";
import SideBar from "../relatedLinksSideBar/links";
import SimpleImageSlider from "react-simple-image-slider";
import { useNavigate } from "react-router-dom";
import AuthGuard from "../../authGaurd";

const Electrical = () => {
    const images = [
        { url: require("../../images/img1.jpg") },
        { url: require("../../images/ee.jpeg") },
        { url: require("../../images/img3.jpg") },
    ];
    const navigate = useNavigate();
    const App = () => {
        return (
            <div>
                <div>
                    <SimpleImageSlider
                        width={1000}
                        height={400}
                        images={images}
                        showBullets={true}
                        showNavs={true}
                        slideDuration={0.5}
                        autoPlay={true}
                    />
                </div>
            </div>

        );
    }
    const peoples = () => {
        navigate('/faculty');
    }
    return (
        <div className="container-electrical">
            <Header />
            <Navbar />
            <div className="container-ee">
                <div className="department-content">
                    <h1>Welcome To The Department Of Electrical Engineering</h1>
                    <div className="container-btn">
                        <button onClick={peoples}>Faculty</button>
                    </div>
                    <div className="app-slider">
                        <App />
                    </div>
                </div>
                <div className="about-dept">
                    <p>The department offers comprehensive undergraduate, graduate, and doctoral programs that cover a broad spectrum of areas within electrical engineering. These areas include but are not limited to power systems, electronics, communications, control systems, signal processing, and electromagnetics. The curriculum is designed to provide students with a strong foundation in both theoretical concepts and practical applications, preparing them for successful careers in industry, academia, and research institutions.</p>
                    <p>The department is also equipped with state-of-the-art laboratories and research facilities. These facilities allow students and faculty to engage in innovative research projects and hands-on learning experiences. The labs are designed to support a wide range of activities, from basic circuit analysis to advanced research in renewable energy, wireless communications, and robotics.</p>
                </div>
            </div>
        </div>

    );
}

export default AuthGuard(Electrical, ['user', 'anon']);